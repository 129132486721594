

function getAssetPath(apath=undefined) {
  if(apath == undefined) return apath

  
    const file_path = {
  "admin/admins.js": "/packs/js/admin/admins-f8a4b318b20ebc202db9.js",
  "admin/admins.js.map": "/packs/js/admin/admins-f8a4b318b20ebc202db9.js.map",
  "admin/bets.js": "/packs/js/admin/bets-e90605705c3e34ff75ff.js",
  "admin/bets.js.map": "/packs/js/admin/bets-e90605705c3e34ff75ff.js.map",
  "admin/club_dependents.js": "/packs/js/admin/club_dependents-7bd2f981a73715e6314e.js",
  "admin/club_dependents.js.map": "/packs/js/admin/club_dependents-7bd2f981a73715e6314e.js.map",
  "admin/clubs.js": "/packs/js/admin/clubs-7f564bb7d2858fbc3ff9.js",
  "admin/clubs.js.map": "/packs/js/admin/clubs-7f564bb7d2858fbc3ff9.js.map",
  "admin/event_types.js": "/packs/js/admin/event_types-fd4a027c501e3d96591d.js",
  "admin/event_types.js.map": "/packs/js/admin/event_types-fd4a027c501e3d96591d.js.map",
  "admin/events.js": "/packs/js/admin/events-11574ef86922d49d0116.js",
  "admin/events.js.map": "/packs/js/admin/events-11574ef86922d49d0116.js.map",
  "admin/federation_events.js": "/packs/js/admin/federation_events-2a89c713ce0a7f23d1f6.js",
  "admin/federation_events.js.map": "/packs/js/admin/federation_events-2a89c713ce0a7f23d1f6.js.map",
  "admin/loot_box_prizes.js": "/packs/js/admin/loot_box_prizes-b492f958b190e4b5dcc2.js",
  "admin/loot_box_prizes.js.map": "/packs/js/admin/loot_box_prizes-b492f958b190e4b5dcc2.js.map",
  "admin/memberships.js": "/packs/js/admin/memberships-b21631b8d1a61e84c161.js",
  "admin/memberships.js.map": "/packs/js/admin/memberships-b21631b8d1a61e84c161.js.map",
  "admin/mystery_boxes.js": "/packs/js/admin/mystery_boxes-8fdee488fcb1857ab50f.js",
  "admin/mystery_boxes.js.map": "/packs/js/admin/mystery_boxes-8fdee488fcb1857ab50f.js.map",
  "admin/owned_boxes.js": "/packs/js/admin/owned_boxes-ff60a44f583987f8e019.js",
  "admin/owned_boxes.js.map": "/packs/js/admin/owned_boxes-ff60a44f583987f8e019.js.map",
  "admin/passwords.js": "/packs/js/admin/passwords-afca6f39fce09171fb48.js",
  "admin/passwords.js.map": "/packs/js/admin/passwords-afca6f39fce09171fb48.js.map",
  "admin/portal.js": "/packs/js/admin/portal-c298a5d8e94e26863749.js",
  "admin/portal.js.map": "/packs/js/admin/portal-c298a5d8e94e26863749.js.map",
  "admin/registrations.js": "/packs/js/admin/registrations-4179995be86fc6579af9.js",
  "admin/registrations.js.map": "/packs/js/admin/registrations-4179995be86fc6579af9.js.map",
  "admin/sessions.js": "/packs/js/admin/sessions-0d24f457ea8ef6754875.js",
  "admin/sessions.js.map": "/packs/js/admin/sessions-0d24f457ea8ef6754875.js.map",
  "admin/transactions.js": "/packs/js/admin/transactions-0f368c6ea130bf843c1a.js",
  "admin/transactions.js.map": "/packs/js/admin/transactions-0f368c6ea130bf843c1a.js.map",
  "admin/users.js": "/packs/js/admin/users-6b64b197e87dc944488c.js",
  "admin/users.js.map": "/packs/js/admin/users-6b64b197e87dc944488c.js.map",
  "app_plugin.js": "/packs/js/app_plugin-4cc5ca984863f6a09a42.js",
  "app_plugin.js.map": "/packs/js/app_plugin-4cc5ca984863f6a09a42.js.map",
  "app_settings.js": "/packs/js/app_settings-5abedc64f1d4bba501bc.js",
  "app_settings.js.map": "/packs/js/app_settings-5abedc64f1d4bba501bc.js.map",
  "application.css": "/packs/css/application-28f0dcf1.css",
  "application.js": "/packs/js/application-48cb0c0a2cefee3d7ee9.js",
  "application.js.map": "/packs/js/application-48cb0c0a2cefee3d7ee9.js.map",
  "assets_manifest.js": "/packs/js/assets_manifest-e7e5beaed251b6c54b03.js",
  "assets_manifest.js.map": "/packs/js/assets_manifest-e7e5beaed251b6c54b03.js.map",
  "datatables.css": "/packs/css/datatables-363b024f.css",
  "datatables.js": "/packs/js/datatables-626f5ef4a935591ff73d.js",
  "datatables.js.map": "/packs/js/datatables-626f5ef4a935591ff73d.js.map",
  "entrypoints": {
    "admin/admins": {
      "js": [
        "/packs/js/admin/admins-f8a4b318b20ebc202db9.js"
      ],
      "js.map": [
        "/packs/js/admin/admins-f8a4b318b20ebc202db9.js.map"
      ]
    },
    "admin/bets": {
      "js": [
        "/packs/js/admin/bets-e90605705c3e34ff75ff.js"
      ],
      "js.map": [
        "/packs/js/admin/bets-e90605705c3e34ff75ff.js.map"
      ]
    },
    "admin/club_dependents": {
      "js": [
        "/packs/js/admin/club_dependents-7bd2f981a73715e6314e.js"
      ],
      "js.map": [
        "/packs/js/admin/club_dependents-7bd2f981a73715e6314e.js.map"
      ]
    },
    "admin/clubs": {
      "js": [
        "/packs/js/admin/clubs-7f564bb7d2858fbc3ff9.js"
      ],
      "js.map": [
        "/packs/js/admin/clubs-7f564bb7d2858fbc3ff9.js.map"
      ]
    },
    "admin/event_types": {
      "js": [
        "/packs/js/admin/event_types-fd4a027c501e3d96591d.js"
      ],
      "js.map": [
        "/packs/js/admin/event_types-fd4a027c501e3d96591d.js.map"
      ]
    },
    "admin/events": {
      "js": [
        "/packs/js/admin/events-11574ef86922d49d0116.js"
      ],
      "js.map": [
        "/packs/js/admin/events-11574ef86922d49d0116.js.map"
      ]
    },
    "admin/federation_events": {
      "js": [
        "/packs/js/admin/federation_events-2a89c713ce0a7f23d1f6.js"
      ],
      "js.map": [
        "/packs/js/admin/federation_events-2a89c713ce0a7f23d1f6.js.map"
      ]
    },
    "admin/loot_box_prizes": {
      "js": [
        "/packs/js/admin/loot_box_prizes-b492f958b190e4b5dcc2.js"
      ],
      "js.map": [
        "/packs/js/admin/loot_box_prizes-b492f958b190e4b5dcc2.js.map"
      ]
    },
    "admin/memberships": {
      "js": [
        "/packs/js/admin/memberships-b21631b8d1a61e84c161.js"
      ],
      "js.map": [
        "/packs/js/admin/memberships-b21631b8d1a61e84c161.js.map"
      ]
    },
    "admin/mystery_boxes": {
      "js": [
        "/packs/js/admin/mystery_boxes-8fdee488fcb1857ab50f.js"
      ],
      "js.map": [
        "/packs/js/admin/mystery_boxes-8fdee488fcb1857ab50f.js.map"
      ]
    },
    "admin/owned_boxes": {
      "js": [
        "/packs/js/admin/owned_boxes-ff60a44f583987f8e019.js"
      ],
      "js.map": [
        "/packs/js/admin/owned_boxes-ff60a44f583987f8e019.js.map"
      ]
    },
    "admin/passwords": {
      "js": [
        "/packs/js/admin/passwords-afca6f39fce09171fb48.js"
      ],
      "js.map": [
        "/packs/js/admin/passwords-afca6f39fce09171fb48.js.map"
      ]
    },
    "admin/portal": {
      "js": [
        "/packs/js/admin/portal-c298a5d8e94e26863749.js"
      ],
      "js.map": [
        "/packs/js/admin/portal-c298a5d8e94e26863749.js.map"
      ]
    },
    "admin/registrations": {
      "js": [
        "/packs/js/admin/registrations-4179995be86fc6579af9.js"
      ],
      "js.map": [
        "/packs/js/admin/registrations-4179995be86fc6579af9.js.map"
      ]
    },
    "admin/sessions": {
      "js": [
        "/packs/js/admin/sessions-0d24f457ea8ef6754875.js"
      ],
      "js.map": [
        "/packs/js/admin/sessions-0d24f457ea8ef6754875.js.map"
      ]
    },
    "admin/transactions": {
      "js": [
        "/packs/js/admin/transactions-0f368c6ea130bf843c1a.js"
      ],
      "js.map": [
        "/packs/js/admin/transactions-0f368c6ea130bf843c1a.js.map"
      ]
    },
    "admin/users": {
      "js": [
        "/packs/js/admin/users-6b64b197e87dc944488c.js"
      ],
      "js.map": [
        "/packs/js/admin/users-6b64b197e87dc944488c.js.map"
      ]
    },
    "app_plugin": {
      "js": [
        "/packs/js/app_plugin-4cc5ca984863f6a09a42.js"
      ],
      "js.map": [
        "/packs/js/app_plugin-4cc5ca984863f6a09a42.js.map"
      ]
    },
    "app_settings": {
      "js": [
        "/packs/js/app_settings-5abedc64f1d4bba501bc.js"
      ],
      "js.map": [
        "/packs/js/app_settings-5abedc64f1d4bba501bc.js.map"
      ]
    },
    "application": {
      "css": [
        "/packs/css/application-28f0dcf1.css"
      ],
      "js": [
        "/packs/js/application-48cb0c0a2cefee3d7ee9.js"
      ],
      "js.map": [
        "/packs/js/application-48cb0c0a2cefee3d7ee9.js.map"
      ]
    },
    "assets_manifest": {
      "js": [
        "/packs/js/assets_manifest-e7e5beaed251b6c54b03.js"
      ],
      "js.map": [
        "/packs/js/assets_manifest-e7e5beaed251b6c54b03.js.map"
      ]
    },
    "datatables": {
      "css": [
        "/packs/css/datatables-363b024f.css"
      ],
      "js": [
        "/packs/js/datatables-626f5ef4a935591ff73d.js"
      ],
      "js.map": [
        "/packs/js/datatables-626f5ef4a935591ff73d.js.map"
      ]
    },
    "hello_coffee": {
      "js": [
        "/packs/js/hello_coffee-8dccba6ef65712653ef6.js"
      ],
      "js.map": [
        "/packs/js/hello_coffee-8dccba6ef65712653ef6.js.map"
      ]
    },
    "hello_erb": {
      "js": [
        "/packs/js/hello_erb-4372c451d7a8a0998b5c.js"
      ],
      "js.map": [
        "/packs/js/hello_erb-4372c451d7a8a0998b5c.js.map"
      ]
    },
    "hello_react": {
      "js": [
        "/packs/js/hello_react-d165c2a16da9b69dcc0f.js"
      ],
      "js.map": [
        "/packs/js/hello_react-d165c2a16da9b69dcc0f.js.map"
      ]
    },
    "plugins": {
      "css": [
        "/packs/css/plugins-6f4fee81.css"
      ],
      "js": [
        "/packs/js/plugins-e94bc3184bb406afa23b.js"
      ],
      "js.map": [
        "/packs/js/plugins-e94bc3184bb406afa23b.js.map"
      ]
    },
    "prismjs": {
      "css": [
        "/packs/css/prismjs-24ca2c9e.css"
      ],
      "js": [
        "/packs/js/prismjs-0f220b2775d5836b7504.js"
      ],
      "js.map": [
        "/packs/js/prismjs-0f220b2775d5836b7504.js.map"
      ]
    },
    "styles": {
      "css": [
        "/packs/css/styles-4e7c7711.css"
      ],
      "js": [
        "/packs/js/styles-e23d707a2ab08877b270.js"
      ],
      "js.map": [
        "/packs/js/styles-e23d707a2ab08877b270.js.map"
      ]
    },
    "styles/errors": {
      "css": [
        "/packs/css/styles/errors-171f2bc8.css"
      ],
      "js": [
        "/packs/js/styles/errors-11afc6916d344436cc55.js"
      ],
      "js.map": [
        "/packs/js/styles/errors-11afc6916d344436cc55.js.map"
      ]
    },
    "styles/form": {
      "css": [
        "/packs/css/styles/form-c69a8b61.css"
      ],
      "js": [
        "/packs/js/styles/form-16d9bed87db69b5511d7.js"
      ],
      "js.map": [
        "/packs/js/styles/form-16d9bed87db69b5511d7.js.map"
      ]
    },
    "styles/fullcalendar": {
      "css": [
        "/packs/css/styles/fullcalendar-a4cfaf80.css"
      ],
      "js": [
        "/packs/js/styles/fullcalendar-b8ff007a1bfa0bdd421e.js"
      ],
      "js.map": [
        "/packs/js/styles/fullcalendar-b8ff007a1bfa0bdd421e.js.map"
      ]
    },
    "styles/login": {
      "css": [
        "/packs/css/styles/login-29c3312b.css"
      ],
      "js": [
        "/packs/js/styles/login-6ce75389682d4dff643d.js"
      ],
      "js.map": [
        "/packs/js/styles/login-6ce75389682d4dff643d.js.map"
      ]
    },
    "styles/wizard-6": {
      "css": [
        "/packs/css/styles/wizard-6-cc6357a8.css"
      ],
      "js": [
        "/packs/js/styles/wizard-6-8259cd95865b559f12bf.js"
      ],
      "js.map": [
        "/packs/js/styles/wizard-6-8259cd95865b559f12bf.js.map"
      ]
    },
    "sweet_alert_confirm": {
      "js": [
        "/packs/js/sweet_alert_confirm-802b0bb3369b5a0f7a43.js"
      ],
      "js.map": [
        "/packs/js/sweet_alert_confirm-802b0bb3369b5a0f7a43.js.map"
      ]
    }
  },
  "hello_coffee.js": "/packs/js/hello_coffee-8dccba6ef65712653ef6.js",
  "hello_coffee.js.map": "/packs/js/hello_coffee-8dccba6ef65712653ef6.js.map",
  "hello_erb.js": "/packs/js/hello_erb-4372c451d7a8a0998b5c.js",
  "hello_erb.js.map": "/packs/js/hello_erb-4372c451d7a8a0998b5c.js.map",
  "hello_react.js": "/packs/js/hello_react-d165c2a16da9b69dcc0f.js",
  "hello_react.js.map": "/packs/js/hello_react-d165c2a16da9b69dcc0f.js.map",
  "media/assets/owl.video.play.png": "/packs/media/assets/owl.video.play-4a37f800.png",
  "media/font/socicon.eot": "/packs/media/font/socicon-60e58570.eot",
  "media/font/socicon.svg": "/packs/media/font/socicon-a35b6574.svg",
  "media/font/socicon.ttf": "/packs/media/font/socicon-9a64ef93.ttf",
  "media/font/socicon.woff": "/packs/media/font/socicon-944f06f5.woff",
  "media/font/summernote.eot": "/packs/media/font/summernote-f4a47ce9.eot",
  "media/font/summernote.ttf": "/packs/media/font/summernote-82fa597f.ttf",
  "media/font/summernote.woff": "/packs/media/font/summernote-c1a96d26.woff",
  "media/font/summernote.woff2": "/packs/media/font/summernote-f694db69.woff2",
  "media/fonts/la-brands-400.eot": "/packs/media/fonts/la-brands-400-a38ca9f0.eot",
  "media/fonts/la-brands-400.svg": "/packs/media/fonts/la-brands-400-9769d0a6.svg",
  "media/fonts/la-brands-400.ttf": "/packs/media/fonts/la-brands-400-bbf83f8b.ttf",
  "media/fonts/la-brands-400.woff": "/packs/media/fonts/la-brands-400-fb598c9c.woff",
  "media/fonts/la-brands-400.woff2": "/packs/media/fonts/la-brands-400-54b0b4e7.woff2",
  "media/fonts/la-regular-400.eot": "/packs/media/fonts/la-regular-400-2746742c.eot",
  "media/fonts/la-regular-400.svg": "/packs/media/fonts/la-regular-400-55c57a74.svg",
  "media/fonts/la-regular-400.ttf": "/packs/media/fonts/la-regular-400-87dab6ff.ttf",
  "media/fonts/la-regular-400.woff": "/packs/media/fonts/la-regular-400-338f6f87.woff",
  "media/fonts/la-regular-400.woff2": "/packs/media/fonts/la-regular-400-88d9d941.woff2",
  "media/fonts/la-solid-900.eot": "/packs/media/fonts/la-solid-900-8c65fd3e.eot",
  "media/fonts/la-solid-900.svg": "/packs/media/fonts/la-solid-900-79be4e9f.svg",
  "media/fonts/la-solid-900.ttf": "/packs/media/fonts/la-solid-900-bb49393b.ttf",
  "media/fonts/la-solid-900.woff": "/packs/media/fonts/la-solid-900-87292218.woff",
  "media/fonts/la-solid-900.woff2": "/packs/media/fonts/la-solid-900-36fc2979.woff2",
  "media/images/app_logo.png": "/packs/media/images/app_logo-3d2e749d113abd67ad68b6d7a126803c.png",
  "media/images/bg/bg-1.jpg": "/packs/media/images/bg/bg-1-e0c2a4f8201e160f0e7fa5f4db88024d.jpg",
  "media/images/bg/bg-10.jpg": "/packs/media/images/bg/bg-10-376474dff1ce99fe4c7508866a73aa2d.jpg",
  "media/images/bg/bg-11.jpg": "/packs/media/images/bg/bg-11-c583e5862b91f7a969665212d6a91170.jpg",
  "media/images/bg/bg-2.jpg": "/packs/media/images/bg/bg-2-d84b02f70d8604c9bc492cc07f5e4706.jpg",
  "media/images/bg/bg-3.jpg": "/packs/media/images/bg/bg-3-6ecf79dbb9a59248510d64773993254e.jpg",
  "media/images/bg/bg-4.jpg": "/packs/media/images/bg/bg-4-832a4d3ff41654d5d835bad73c2784d2.jpg",
  "media/images/bg/bg-5.jpg": "/packs/media/images/bg/bg-5-291231257db974eb2e53a17657d510ad.jpg",
  "media/images/bg/bg-6.jpg": "/packs/media/images/bg/bg-6-9ebaccb57c3b0b2e19aea2f4adf2ea01.jpg",
  "media/images/bg/bg-8.jpg": "/packs/media/images/bg/bg-8-245e9c9d07542e43f3af23340ec19a06.jpg",
  "media/images/bg/bg-9.jpg": "/packs/media/images/bg/bg-9-458f09583a8d3ec1d9e1b8fa992a72b0.jpg",
  "media/images/bg/bg-invoice-5.jpg": "/packs/media/images/bg/bg-invoice-5-c80b145dbec5b3af38f03edef2d9d5a1.jpg",
  "media/images/bg/demo-7.jpg": "/packs/media/images/bg/demo-7-f5c10117424f42056d347c00d1d27229.jpg",
  "media/images/bg/home-2-bg.svg": "/packs/media/images/bg/home-2-bg-575a1272e4887a6a9f3ef193c1706d30.svg",
  "media/images/bg/sc-home1-bg.png": "/packs/media/images/bg/sc-home1-bg-43f4980dbd5aabc07682344afa5c04d9.png",
  "media/images/client-logos/logo1.png": "/packs/media/images/client-logos/logo1-86fabd78d706407f378d962ff9926b60.png",
  "media/images/client-logos/logo2.png": "/packs/media/images/client-logos/logo2-ef32d16932faf563ffa3e954c78f4a87.png",
  "media/images/client-logos/logo3.png": "/packs/media/images/client-logos/logo3-62ac147ea62b8e50cb691e5072642bd1.png",
  "media/images/client-logos/logo4.png": "/packs/media/images/client-logos/logo4-543743ea9e063449e6162bf13fe746dc.png",
  "media/images/client-logos/logo5.png": "/packs/media/images/client-logos/logo5-13b1ca5e16a1b5fe24cb6b308033af05.png",
  "media/images/error/bg1.jpg": "/packs/media/images/error/bg1-7e3bd3aabbaa9bf50a27775a7003bc46.jpg",
  "media/images/error/bg2.jpg": "/packs/media/images/error/bg2-9cc269f7ff7fae72ce196a564496dd8b.jpg",
  "media/images/error/bg3.jpg": "/packs/media/images/error/bg3-ff13001e6e0fa9e501e430f06850d156.jpg",
  "media/images/error/bg4.jpg": "/packs/media/images/error/bg4-fd6931f5ac09e473e285c005a07c838f.jpg",
  "media/images/error/bg5.jpg": "/packs/media/images/error/bg5-144bb17867c3e7a9a241b53385a4842b.jpg",
  "media/images/error/bg6.jpg": "/packs/media/images/error/bg6-68b1cecde01f64d56b7fbbe67f6dbcdd.jpg",
  "media/images/favicon.ico": "/packs/media/images/favicon-082f3dc184461949257a9b68e14f0f9e.ico",
  "media/images/logos/favicon.ico": "/packs/media/images/logos/favicon-6329798aad91752c4c2c9a50549e4384.ico",
  "media/images/logos/logo-1.png": "/packs/media/images/logos/logo-1-ff45f3455829ece6617eeca2368a95a7.png",
  "media/images/logos/logo-4.png": "/packs/media/images/logos/logo-4-ca271136efb534d79cef977601adf8eb.png",
  "media/images/logos/logo-dark.png": "/packs/media/images/logos/logo-dark-8096015b42e2f8c965ab705360bec1c0.png",
  "media/images/logos/logo-default-inverse.png": "/packs/media/images/logos/logo-default-inverse-d5cbf1a03e0f8b9ac5a311f563796e3f.png",
  "media/images/logos/logo-default.png": "/packs/media/images/logos/logo-default-5fce849f40925558e8763caec9d75abe.png",
  "media/images/logos/logo-letter-1.png": "/packs/media/images/logos/logo-letter-1-6ca0410f9f7f3b3439aa59cab904d0c4.png",
  "media/images/logos/logo-letter-10.png": "/packs/media/images/logos/logo-letter-10-77b82462cb235ed95db0825764a09087.png",
  "media/images/logos/logo-letter-11.png": "/packs/media/images/logos/logo-letter-11-110c3c793bc3d6e0a8cf81a6e8bb93b4.png",
  "media/images/logos/logo-letter-12.png": "/packs/media/images/logos/logo-letter-12-6a3148f4db8c538079d5d09bd124a057.png",
  "media/images/logos/logo-letter-13.png": "/packs/media/images/logos/logo-letter-13-d6bb8ae7d84321e95e7bb4d456795a6e.png",
  "media/images/logos/logo-letter-2.png": "/packs/media/images/logos/logo-letter-2-8af5318c929506bac59b3ba7d674bffb.png",
  "media/images/logos/logo-letter-3.png": "/packs/media/images/logos/logo-letter-3-713349bd6a73b946bde28ff77e725d2f.png",
  "media/images/logos/logo-letter-4.png": "/packs/media/images/logos/logo-letter-4-efdeed0c6d968c125b07b98e9c48c367.png",
  "media/images/logos/logo-letter-5.png": "/packs/media/images/logos/logo-letter-5-f6c5fc33f49ce43e614e17ee955af9f5.png",
  "media/images/logos/logo-letter-6.png": "/packs/media/images/logos/logo-letter-6-176708a597be470887c6ebfb735ec0f5.png",
  "media/images/logos/logo-letter-7.png": "/packs/media/images/logos/logo-letter-7-d04cca06b7bb73cb6e324d768fe68fed.png",
  "media/images/logos/logo-letter-8.png": "/packs/media/images/logos/logo-letter-8-a1e5fffaa836b21461249828c92194fd.png",
  "media/images/logos/logo-letter-9.png": "/packs/media/images/logos/logo-letter-9-7c37c9998fd09c624d5d9e7588b415e5.png",
  "media/images/logos/logo-light.png": "/packs/media/images/logos/logo-light-1a9d17a0eac3fed9f6851ad4383bb5a6.png",
  "media/images/logos/logo.png": "/packs/media/images/logos/logo-def9371e05a03ecceceb62f1665e824b.png",
  "media/images/logos/stars.png": "/packs/media/images/logos/stars-b35ff1d96a5f9182e94e33a87cd947d1.png",
  "media/images/misc/bg-1.jpg": "/packs/media/images/misc/bg-1-78ed2040c21a3c1152696fcf378e690d.jpg",
  "media/images/misc/bg-2.jpg": "/packs/media/images/misc/bg-2-a8b7ee1a2cef20c1ed0a939d05a33f90.jpg",
  "media/images/misc/invoice 5 bar.jpg": "/packs/media/images/misc/invoice%205%20bar-3620f3ee84ba50656e5b71b34d196658.jpg",
  "media/images/plugins/jstree/32px.png": "/packs/media/images/plugins/jstree/32px-e1d0c01e695c145fdd66ecc07e06268e.png",
  "media/images/users/100_1.jpg": "/packs/media/images/users/100_1-1f7beca5bf4cda1803cd59cd881c372d.jpg",
  "media/images/users/100_10.jpg": "/packs/media/images/users/100_10-56d054ffdf15ac516d7ba19fa9a33ad5.jpg",
  "media/images/users/100_11.jpg": "/packs/media/images/users/100_11-336faadc989ea15bbe2c0fb30e20bcd7.jpg",
  "media/images/users/100_12.jpg": "/packs/media/images/users/100_12-1e0889afa5d1fa653fef4f8b5d3272d2.jpg",
  "media/images/users/100_13.jpg": "/packs/media/images/users/100_13-63265e929c9f7442ea1853d2dc19a188.jpg",
  "media/images/users/100_14.jpg": "/packs/media/images/users/100_14-595b8cbdd2b7f27cf7d38b895a9b56c9.jpg",
  "media/images/users/100_2.jpg": "/packs/media/images/users/100_2-82e7c41e073394f808fd382c97e71884.jpg",
  "media/images/users/100_3.jpg": "/packs/media/images/users/100_3-6e25d86d33b97ab231889fd4a38530b4.jpg",
  "media/images/users/100_4.jpg": "/packs/media/images/users/100_4-978e51b554db00a67e2796661b3526fe.jpg",
  "media/images/users/100_5.jpg": "/packs/media/images/users/100_5-fd53372585bf0ef377e97ee9dd7ea925.jpg",
  "media/images/users/100_6.jpg": "/packs/media/images/users/100_6-de88253696b81598494dd6bd5c0b087e.jpg",
  "media/images/users/100_7.jpg": "/packs/media/images/users/100_7-1dce100ffdb944226acf9db666a57319.jpg",
  "media/images/users/100_8.jpg": "/packs/media/images/users/100_8-a4be0bbf3a08f9de24548aa6765af93f.jpg",
  "media/images/users/100_9.jpg": "/packs/media/images/users/100_9-798c02eb53d02e11c3d8a6588cdf5033.jpg",
  "media/images/users/300_1.jpg": "/packs/media/images/users/300_1-c0bc77c17ea421e907129338c85f4a5a.jpg",
  "media/images/users/300_10.jpg": "/packs/media/images/users/300_10-9d66205a4d4faee62c42e7ecd37a5a3f.jpg",
  "media/images/users/300_11.jpg": "/packs/media/images/users/300_11-fde8b07ca83180dc60797f81d140317c.jpg",
  "media/images/users/300_12.jpg": "/packs/media/images/users/300_12-bade970b5095ce682f1b3935ed584a0a.jpg",
  "media/images/users/300_13.jpg": "/packs/media/images/users/300_13-cc2a79321add27edaab35355dab526ef.jpg",
  "media/images/users/300_14.jpg": "/packs/media/images/users/300_14-46388e71123397c45c9db3c011717f04.jpg",
  "media/images/users/300_15.jpg": "/packs/media/images/users/300_15-0b794c49b990f145288d7e0c8196166b.jpg",
  "media/images/users/300_16.jpg": "/packs/media/images/users/300_16-504b08b7a67950bab555d9c5aecbeaa2.jpg",
  "media/images/users/300_17.jpg": "/packs/media/images/users/300_17-83264b6b6ad778267da25cf1ce4080d8.jpg",
  "media/images/users/300_18.jpg": "/packs/media/images/users/300_18-51ea1150bb6c39238803f55ff74d607a.jpg",
  "media/images/users/300_19.jpg": "/packs/media/images/users/300_19-ea292b9f85c61a030a9a6c48e3646c24.jpg",
  "media/images/users/300_2.jpg": "/packs/media/images/users/300_2-0c886456170b400e78eacc0bdcf5eef9.jpg",
  "media/images/users/300_20.jpg": "/packs/media/images/users/300_20-5afd45fd362317743c90466034e134d0.jpg",
  "media/images/users/300_21.jpg": "/packs/media/images/users/300_21-e78f5a1f1ec6b0e11e02efdb3884d0b6.jpg",
  "media/images/users/300_22.jpg": "/packs/media/images/users/300_22-0c3b0fad4c89d5662d9d1945e72a2c17.jpg",
  "media/images/users/300_23.jpg": "/packs/media/images/users/300_23-988543f6b5a972668a6fba76251237b7.jpg",
  "media/images/users/300_24.jpg": "/packs/media/images/users/300_24-62db1449bc7f571de2e04315dd22d3e1.jpg",
  "media/images/users/300_25.jpg": "/packs/media/images/users/300_25-b5bc9ee931bdcc0c439702898a9dc0d2.jpg",
  "media/images/users/300_3.jpg": "/packs/media/images/users/300_3-c4b8cd72718d850e93708c4027360a43.jpg",
  "media/images/users/300_4.jpg": "/packs/media/images/users/300_4-a0261f72e06c111610b701a96572a6ee.jpg",
  "media/images/users/300_5.jpg": "/packs/media/images/users/300_5-c9e0d2abc53975895fd819203ef5c380.jpg",
  "media/images/users/300_6.jpg": "/packs/media/images/users/300_6-9c2bbb1d47a1571a53088556d8e2309b.jpg",
  "media/images/users/300_7.jpg": "/packs/media/images/users/300_7-99ad042cab1c6abb749e42838065e945.jpg",
  "media/images/users/300_8.jpg": "/packs/media/images/users/300_8-1be916fdc2e3b5ba8a82cb097dc4a6ce.jpg",
  "media/images/users/300_9.jpg": "/packs/media/images/users/300_9-9e7c7e4f48638b9eb0958a729fd09281.jpg",
  "media/images/users/blank.png": "/packs/media/images/users/blank-ec0d27963118a65090cc877e95909d44.png",
  "media/images/users/default.jpg": "/packs/media/images/users/default-c1b2178526f98c9ae6aa748b39b999f8.jpg",
  "media/images/users/user1.jpg": "/packs/media/images/users/user1-5a8a97e45d37f1faf0e3d135d36d0107.jpg",
  "media/images/users/user2.jpg": "/packs/media/images/users/user2-2cb74263534aefc00b61cba6faa16778.jpg",
  "media/images/users/user3.jpg": "/packs/media/images/users/user3-1334b81229a2ba20adf2302f630b1019.jpg",
  "media/images/users/user4.jpg": "/packs/media/images/users/user4-035bc66f0bcde330c09503229745f219.jpg",
  "media/images/users/user5.jpg": "/packs/media/images/users/user5-3c598286f96d98fd6bfe1c955b1f27d3.jpg",
  "media/metronic/plugins/flaticon/font/Flaticon.eot": "/packs/media/metronic/plugins/flaticon/font/Flaticon-35d544eaaa4cf3c6355866280d53ba73.eot",
  "media/metronic/plugins/flaticon/font/Flaticon.svg": "/packs/media/metronic/plugins/flaticon/font/Flaticon-500d63cca8c534718b1d202b2076d218.svg",
  "media/metronic/plugins/flaticon/font/Flaticon.ttf": "/packs/media/metronic/plugins/flaticon/font/Flaticon-3e4331ee31764c999add7e0b048c4ba3.ttf",
  "media/metronic/plugins/flaticon/font/Flaticon.woff": "/packs/media/metronic/plugins/flaticon/font/Flaticon-5be3e43c13c3eb021d15e6682d098d4c.woff",
  "media/metronic/plugins/flaticon2/font/Flaticon2.eot": "/packs/media/metronic/plugins/flaticon2/font/Flaticon2-29586ff0f963f4d1fdfc182822b8b27a.eot",
  "media/metronic/plugins/flaticon2/font/Flaticon2.svg": "/packs/media/metronic/plugins/flaticon2/font/Flaticon2-4248a89254d2c6c396d85e378184c54f.svg",
  "media/metronic/plugins/flaticon2/font/Flaticon2.ttf": "/packs/media/metronic/plugins/flaticon2/font/Flaticon2-eafcbac04cdb0a39fe38a36ebd786290.ttf",
  "media/metronic/plugins/flaticon2/font/Flaticon2.woff": "/packs/media/metronic/plugins/flaticon2/font/Flaticon2-b242ac810bd8cccaa03abc2128b7c3c3.woff",
  "media/metronic/plugins/keenthemes-icons/font/Ki.eot": "/packs/media/metronic/plugins/keenthemes-icons/font/Ki-31f3692ca10d2f6d0bc467adba4533f7.eot",
  "media/metronic/plugins/keenthemes-icons/font/Ki.svg": "/packs/media/metronic/plugins/keenthemes-icons/font/Ki-34533d8034c943ae211ea85db35bfba9.svg",
  "media/metronic/plugins/keenthemes-icons/font/Ki.ttf": "/packs/media/metronic/plugins/keenthemes-icons/font/Ki-2a5ff834d12f21b421748941d6057d04.ttf",
  "media/metronic/plugins/keenthemes-icons/font/Ki.woff": "/packs/media/metronic/plugins/keenthemes-icons/font/Ki-8f9a1a6cffbd1826644e12714ec106d0.woff",
  "media/poppin/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw-b9f2c13fc3617c3b1bba74575cc473e8.woff2",
  "media/poppin/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw-62d7a4c78fc2b6d74fa84c0ee8f9f7eb.woff2",
  "media/poppin/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk-f4f17fd53c7d040e56f91a3ecb692b22.woff2",
  "media/poppin/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw-ee36c39c2b2f8587a788489da7df7420.woff2",
  "media/poppin/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw-f99245320288ea1579078fb33ad36e8d.woff2",
  "media/poppin/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk-9ddc04912d6e8f88d9de4045b8b89c59.woff2",
  "media/poppin/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw-3ab1d13f4b6148d6b45d9a8d975411bc.woff2",
  "media/poppin/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw-8ed7cb7cb3f025303cdbedfa05d6cf54.woff2",
  "media/poppin/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk-087457026965f98466618a478c4b1b07.woff2",
  "media/poppin/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw-fd9b8290076bb123b576fa8a79ad7986.woff2",
  "media/poppin/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw-d214b888d8959093716f8af6761a0226.woff2",
  "media/poppin/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2": "/packs/media/poppin/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk-84780596e268aa0cb2be48af2ed5c375.woff2",
  "media/poppin/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2": "/packs/media/poppin/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ-d5e78c53cb0716cfec0b96fe178870f9.woff2",
  "media/poppin/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2": "/packs/media/poppin/pxiEyp8kv8JHgFVrJJfecnFHGPc-9ed361bba8488aeb2797b82befda20f1.woff2",
  "media/poppin/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2": "/packs/media/poppin/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ-4d1490f32451df75d9cdcccb0c82874c.woff2",
  "media/webfonts/fa-brands-400.eot": "/packs/media/webfonts/fa-brands-400-30cc681d.eot",
  "media/webfonts/fa-brands-400.svg": "/packs/media/webfonts/fa-brands-400-ba7ed552.svg",
  "media/webfonts/fa-brands-400.ttf": "/packs/media/webfonts/fa-brands-400-3b89dd10.ttf",
  "media/webfonts/fa-brands-400.woff": "/packs/media/webfonts/fa-brands-400-099a9556.woff",
  "media/webfonts/fa-brands-400.woff2": "/packs/media/webfonts/fa-brands-400-f7307680.woff2",
  "media/webfonts/fa-regular-400.eot": "/packs/media/webfonts/fa-regular-400-7630483d.eot",
  "media/webfonts/fa-regular-400.svg": "/packs/media/webfonts/fa-regular-400-0bb42845.svg",
  "media/webfonts/fa-regular-400.ttf": "/packs/media/webfonts/fa-regular-400-1f77739c.ttf",
  "media/webfonts/fa-regular-400.woff": "/packs/media/webfonts/fa-regular-400-7124eb50.woff",
  "media/webfonts/fa-regular-400.woff2": "/packs/media/webfonts/fa-regular-400-f0f82301.woff2",
  "media/webfonts/fa-solid-900.eot": "/packs/media/webfonts/fa-solid-900-1042e8ca.eot",
  "media/webfonts/fa-solid-900.svg": "/packs/media/webfonts/fa-solid-900-376c1f97.svg",
  "media/webfonts/fa-solid-900.ttf": "/packs/media/webfonts/fa-solid-900-605ed792.ttf",
  "media/webfonts/fa-solid-900.woff": "/packs/media/webfonts/fa-solid-900-9fe5a17c.woff",
  "media/webfonts/fa-solid-900.woff2": "/packs/media/webfonts/fa-solid-900-e8a427e1.woff2",
  "plugins.css": "/packs/css/plugins-6f4fee81.css",
  "plugins.js": "/packs/js/plugins-e94bc3184bb406afa23b.js",
  "plugins.js.map": "/packs/js/plugins-e94bc3184bb406afa23b.js.map",
  "prismjs.css": "/packs/css/prismjs-24ca2c9e.css",
  "prismjs.js": "/packs/js/prismjs-0f220b2775d5836b7504.js",
  "prismjs.js.map": "/packs/js/prismjs-0f220b2775d5836b7504.js.map",
  "styles.css": "/packs/css/styles-4e7c7711.css",
  "styles.js": "/packs/js/styles-e23d707a2ab08877b270.js",
  "styles.js.map": "/packs/js/styles-e23d707a2ab08877b270.js.map",
  "styles/errors.css": "/packs/css/styles/errors-171f2bc8.css",
  "styles/errors.js": "/packs/js/styles/errors-11afc6916d344436cc55.js",
  "styles/errors.js.map": "/packs/js/styles/errors-11afc6916d344436cc55.js.map",
  "styles/form.css": "/packs/css/styles/form-c69a8b61.css",
  "styles/form.js": "/packs/js/styles/form-16d9bed87db69b5511d7.js",
  "styles/form.js.map": "/packs/js/styles/form-16d9bed87db69b5511d7.js.map",
  "styles/fullcalendar.css": "/packs/css/styles/fullcalendar-a4cfaf80.css",
  "styles/fullcalendar.js": "/packs/js/styles/fullcalendar-b8ff007a1bfa0bdd421e.js",
  "styles/fullcalendar.js.map": "/packs/js/styles/fullcalendar-b8ff007a1bfa0bdd421e.js.map",
  "styles/login.css": "/packs/css/styles/login-29c3312b.css",
  "styles/login.js": "/packs/js/styles/login-6ce75389682d4dff643d.js",
  "styles/login.js.map": "/packs/js/styles/login-6ce75389682d4dff643d.js.map",
  "styles/wizard-6.css": "/packs/css/styles/wizard-6-cc6357a8.css",
  "styles/wizard-6.js": "/packs/js/styles/wizard-6-8259cd95865b559f12bf.js",
  "styles/wizard-6.js.map": "/packs/js/styles/wizard-6-8259cd95865b559f12bf.js.map",
  "sweet_alert_confirm.js": "/packs/js/sweet_alert_confirm-802b0bb3369b5a0f7a43.js",
  "sweet_alert_confirm.js.map": "/packs/js/sweet_alert_confirm-802b0bb3369b5a0f7a43.js.map"
}[apath]
    if(file_path == undefined) throw `${apath} not present.`

    return file_path
  
}

export {getAssetPath as image_pack_path}